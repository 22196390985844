import { NavigationType, ROLE, StorageKey } from '@shared-libs/enums';

import { BackgroundColorService } from '@shared-services/background-color.service';
import { ILoginCredentials } from '@shared-libs/interfaces';
import { IUser } from '@shared/models/user.model';
import { Injectable } from '@angular/core';
import { LoginProvider } from '@shared-providers/beecloud/login.provider';
import { NavController } from '@ionic/angular';
import { NavigationService } from '@shared-services/navigation.service';
import { StorageService } from '@shared-services/storage.service';
import { UserDetailsManager } from '@shared-managers/user-details.manager';
import { UserManager } from '@shared-managers/user.manager';

/**
 * The authentication service to handle authentication logic
 */
@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	private authenticated: boolean;

	constructor(
		private readonly loginProvider: LoginProvider,
		private readonly navController: NavController,
		private readonly userDetailsManager: UserDetailsManager,
		private readonly userManager: UserManager,
		private readonly storageService: StorageService,
		private readonly backgroundColorService: BackgroundColorService,
		private readonly navigationService: NavigationService
	) {}

	/**
	 * Login, i.e. get a token from the beecloud api to authenticate future requests
	 *
	 * When a user is logged in, the user object is added the the user manager
	 * and saved if the user wants to keep being logged in.
	 * @param _credentials The login credentials as {@link ILoginCredentials}
	 */
	public login(_credentials: ILoginCredentials): void {
		this.loginProvider.login(_credentials).subscribe((result) => {
			delete (result.data as any).message;
			const user: IUser = result.data as IUser;
			this.userManager.setUser(user);
			if (_credentials.keepMeLoggedIn) {
				this.userManager.saveUser();
			}
			this.authenticated = true;
			this.navigateToRoleDefault(user.role);
		});
	}

	/**
	 * Navigate to routing module for a specific role
	 * @param role The user's role
	 */
	public navigateToRoleDefault(role: ROLE): void {
		switch (role) {
			case ROLE.PARTNER:
				void this.navigationService.navigateTo(NavigationType.page, '/partner').catch();
				break;
			case ROLE.CONSULTANT:
				void this.navigationService.navigateTo(NavigationType.page, '/consultant').catch();
				break;
			case ROLE.DISPATCH:
			case ROLE.ADMIN:
			case ROLE.SUPER_ADMIN:
				void this.navigationService.navigateTo(NavigationType.page, '/admin').catch();
				break;
		}
	}

	/**
	 * Logout the current user
	 *
	 * All user and application data is removed from session and persistent storage
	 */
	public logout(): void {
		this.userManager.removeUser();
		this.userDetailsManager.removeUserDetails();
		for (const storageKey of Object.values(StorageKey)) {
			this.storageService.removeItem(storageKey);
		}
		this.backgroundColorService.removeBackgroundColor();
		this.authenticated = false;
		void this.navController.navigateBack('login', { animated: false }).catch();
	}

	/**
	 * A function used by the router authguard to make sure a user is authenticated when trying to access an url
	 * @returns Whether the user is authenticated
	 */
	public isAuthenticated(): Promise<boolean> {
		return new Promise((resolve) => {
			this.userManager.getUser().subscribe({
				next: async () => {
					this.authenticated = true;
					resolve(this.authenticated);
				},
				error: () => {
					this.authenticated = false;
					resolve(this.authenticated);
				},
			});
		});
	}
}
