/* eslint-disable no-console */
import { Component, HostListener } from '@angular/core';

import { AppManager } from '@app/modules/shared/managers/app.manager';
import { BackgroundColorService } from '@app/modules/shared/services/background-color.service';
import { ModalController, Platform } from '@ionic/angular';
import { SharedAppInitialisationService } from '@shared-services/app-initialisation.service';
import { SplashScreen } from '@capacitor/splash-screen';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
})
export class AppComponent {
	constructor(
		private readonly platform: Platform,
		private readonly appManager: AppManager,
		private readonly backgroundColorService: BackgroundColorService,
		private readonly initialisationService: SharedAppInitialisationService,
		private readonly modalController: ModalController
	) {
		this.initializeApp();
	}

	@HostListener('ionModalWillPresent')
	async ionModalWillPresent(): Promise<void> {
		this.backgroundColorService.resetBackgroundColor();
	}

	@HostListener('ionModalWillDismiss')
	ionModalWillDismiss(): void {
		this.backgroundColorService.setPreviousBackgroundColor();
	}

	private initializeApp(): void {
		void this.platform
			.ready()
			.then(async () => {
				void this.platform.resume.subscribe(async () => {
					await this.initialisationService.userIsActive();
				});
				this.appManager.loadMenuState();
				if (this.platform.is('capacitor')) {
					void SplashScreen.hide().catch();
				}
			})
			.catch();
	}
}
