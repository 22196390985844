import { ROLE, StorageKey } from '@shared-libs/enums';

import { IUser } from '@shared/models/user.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { StorageService } from '@shared-services/storage.service';

/* TODO: replace managers with redux strategy? */

/**
 * The manager for the user object. A Manager is responsible for the state and reusability (across the application) of certain properties
 * @see {@link IUser}
 */
@Injectable({
	providedIn: 'root',
})
export class UserManager {
	private user: IUser;

	constructor(private readonly storageService: StorageService) {}

	/**
	 * Set the user object in session storage
	 * @param _user The user that is set
	 */
	public setUser(_user: IUser): void {
		this.user = _user;
	}

	/**
	 * Save the user currently in session storage to persistent storage
	 */
	public saveUser(): void {
		if (this.user) {
			this.storageService.setItem(StorageKey.user, this.user).subscribe();
		}
	}

	/**
	 * Get the user, firstly from session storage and secondly from persistent storage
	 * @returns An observable that returns a {@link IUser}
	 */
	public getUser(): Observable<IUser> {
		return new Observable((subscriber) => {
			if (this.user) {
				subscriber.next(this.user);
			} else {
				return this.storageService.getItem<IUser>(StorageKey.user).subscribe({
					next: (user) => {
						if (user && user.token) {
							this.setUser(user);
							subscriber.next(user);
						} else subscriber.error();
					},
					error: (error) => subscriber.error(error),
				});
			}
		});
	}

	/**
	 * Remove the user from session and persistent storage
	 */
	public removeUser(): void {
		this.user = null;
		this.storageService.removeItem(StorageKey.user).subscribe();
	}

	/**
	 * Get the EmployeeId of the current user.
	 * @returns The employee id
	 */
	public getEmployeeId(): string {
		return this.user ? this.user.EmployeeId : null;
	}

	/**
	 * Get the PartnerId of the current user.
	 * @returns The partner id
	 */
	public getPartnerId(): string {
		return this.user ? this.user.PartnerId : null;
	}

	/**
	 * Get the ClientId of the current user.
	 * @returns The partner id
	 */
	public getClientId(): string {
		return this.user ? this.user.ClientId : null;
	}

	/**
	 * Get the beecloud api token of the current user.
	 * It is used as authorization method for beecloud api requests.
	 * @returns The beecloud api token
	 */
	public getToken(): string {
		return this.user ? this.user.token : null;
	}

	/**
	 * Set the token of the current user
	 * @param _token A new token for the user
	 */
	public setToken(_token: string): void {
		if (this.user) this.user.token = _token;
	}

	/**
	 * Set the token of the current user in persistent storage
	 * @param token A new token for the user
	 */
	public saveToken(token?: string): void {
		if (token) {
			this.user.token = token;
		}
		if (this.user.token) {
			this.storageService.setItem(StorageKey.user, this.user).subscribe(() => {});
		}
	}

	/**
	 * Get the refresh token of the current user.
	 * It is used to get a new token when it is expired
	 * @returns The refresh token
	 */
	public getRefreshToken(): string {
		return this.user ? this.user.refreshToken : null;
	}

	/**
	 * Get the user id of the current user
	 * @returns The user id
	 */
	public getUserId(): string {
		return this.user ? this.user.UserId : null;
	}

	/**
	 * Get the user's name of the current user
	 * @returns The name
	 */
	public getName(): string {
		return this.user ? this.user.name : null;
	}

	/**
	 * Set the user's name of the current user
	 * @param name The name of the current user
	 */
	public setName(name: string): void {
		this.user.name = name;
	}

	/**
	 * Get the role of the current user
	 * @returns The role as {@link ROLE}
	 */
	public getRole(): ROLE {
		return this.user ? this.user.role : null;
	}

	/**
	 * Check if the current user is a consultant
	 * @returns Whether the current user is a consultant or not
	 */
	public isConsultant(): boolean {
		return this.getRole() === ROLE.CONSULTANT;
	}

	public isConsultantOrInternal(): boolean {
		return (
			this.getRole() === ROLE.CONSULTANT ||
			this.getRole() === ROLE.ADMIN ||
			this.getRole() === ROLE.SUPER_ADMIN ||
			this.getRole() === ROLE.DISPATCH
		);
	}

	/**
	 * Check if the current user is a consultant or a partner
	 * @returns Wheter the current user is a consultant/partner or not
	 */
	public isConsultantOrPartner(): boolean {
		return (
			this.getRole() === ROLE.CONSULTANT ||
			this.getRole() === ROLE.SOCIAL_PARTNER ||
			this.getRole() === ROLE.PARTNER
		);
	}
}
